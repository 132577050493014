/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoyaltyAccount } from './LoyaltyAccount';
import {
    LoyaltyAccountFromJSON,
    LoyaltyAccountFromJSONTyped,
    LoyaltyAccountToJSON,
} from './LoyaltyAccount';

/**
 * 
 * @export
 * @interface LoyaltyAccountsResponse
 */
export interface LoyaltyAccountsResponse {
    /**
     * 
     * @type {Array<LoyaltyAccount>}
     * @memberof LoyaltyAccountsResponse
     */
    items?: Array<LoyaltyAccount>;
    /**
     * The total number of loyalty accounts
     * @type {number}
     * @memberof LoyaltyAccountsResponse
     */
    accountTotal?: number;
}

/**
 * Check if a given object implements the LoyaltyAccountsResponse interface.
 */
export function instanceOfLoyaltyAccountsResponse(value: object): value is LoyaltyAccountsResponse {
    return true;
}

export function LoyaltyAccountsResponseFromJSON(json: any): LoyaltyAccountsResponse {
    return LoyaltyAccountsResponseFromJSONTyped(json, false);
}

export function LoyaltyAccountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyAccountsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(LoyaltyAccountFromJSON)),
        'accountTotal': json['account_total'] == null ? undefined : json['account_total'],
    };
}

export function LoyaltyAccountsResponseToJSON(value?: LoyaltyAccountsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(LoyaltyAccountToJSON)),
        'account_total': value['accountTotal'],
    };
}

