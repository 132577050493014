/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoyaltyPerks
 */
export interface LoyaltyPerks {
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPerks
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    programName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    programTier?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    pointsPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    checkoutPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    roomPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    giftPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    supportPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    loungePerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    partnerPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    baggagePerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    cabinPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    mileagePerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    upgradePerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    statusPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    foodPerks?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPerks
     */
    changePerks?: string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPerks
     */
    bonusPointsMultiplier?: number;
}

/**
 * Check if a given object implements the LoyaltyPerks interface.
 */
export function instanceOfLoyaltyPerks(value: object): value is LoyaltyPerks {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function LoyaltyPerksFromJSON(json: any): LoyaltyPerks {
    return LoyaltyPerksFromJSONTyped(json, false);
}

export function LoyaltyPerksFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyPerks {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'programName': json['program_name'] == null ? undefined : json['program_name'],
        'programTier': json['program_tier'] == null ? undefined : json['program_tier'],
        'pointsPerks': json['points_perks'] == null ? undefined : json['points_perks'],
        'checkoutPerks': json['checkout_perks'] == null ? undefined : json['checkout_perks'],
        'roomPerks': json['room_perks'] == null ? undefined : json['room_perks'],
        'giftPerks': json['gift_perks'] == null ? undefined : json['gift_perks'],
        'supportPerks': json['support_perks'] == null ? undefined : json['support_perks'],
        'loungePerks': json['lounge_perks'] == null ? undefined : json['lounge_perks'],
        'partnerPerks': json['partner_perks'] == null ? undefined : json['partner_perks'],
        'baggagePerks': json['baggage_perks'] == null ? undefined : json['baggage_perks'],
        'cabinPerks': json['cabin_perks'] == null ? undefined : json['cabin_perks'],
        'mileagePerks': json['mileage_perks'] == null ? undefined : json['mileage_perks'],
        'upgradePerks': json['upgrade_perks'] == null ? undefined : json['upgrade_perks'],
        'statusPerks': json['status_perks'] == null ? undefined : json['status_perks'],
        'foodPerks': json['food_perks'] == null ? undefined : json['food_perks'],
        'changePerks': json['change_perks'] == null ? undefined : json['change_perks'],
        'bonusPointsMultiplier': json['bonus_points_multiplier'] == null ? undefined : json['bonus_points_multiplier'],
    };
}

export function LoyaltyPerksToJSON(value?: LoyaltyPerks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'program_name': value['programName'],
        'program_tier': value['programTier'],
        'points_perks': value['pointsPerks'],
        'checkout_perks': value['checkoutPerks'],
        'room_perks': value['roomPerks'],
        'gift_perks': value['giftPerks'],
        'support_perks': value['supportPerks'],
        'lounge_perks': value['loungePerks'],
        'partner_perks': value['partnerPerks'],
        'baggage_perks': value['baggagePerks'],
        'cabin_perks': value['cabinPerks'],
        'mileage_perks': value['mileagePerks'],
        'upgrade_perks': value['upgradePerks'],
        'status_perks': value['statusPerks'],
        'food_perks': value['foodPerks'],
        'change_perks': value['changePerks'],
        'bonus_points_multiplier': value['bonusPointsMultiplier'],
    };
}

