/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Promotion } from './Promotion';
import {
    PromotionFromJSON,
    PromotionFromJSONTyped,
    PromotionToJSON,
} from './Promotion';

/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * This user's referral code which can be used to make referrals.
     * @type {string}
     * @memberof Referral
     */
    referralCode?: string;
    /**
     * 
     * @type {User}
     * @memberof Referral
     */
    referredBy?: User;
    /**
     * 
     * @type {Promotion}
     * @memberof Referral
     */
    promotion?: Promotion;
}

/**
 * Check if a given object implements the Referral interface.
 */
export function instanceOfReferral(value: object): value is Referral {
    return true;
}

export function ReferralFromJSON(json: any): Referral {
    return ReferralFromJSONTyped(json, false);
}

export function ReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referral {
    if (json == null) {
        return json;
    }
    return {
        
        'referralCode': json['referral_code'] == null ? undefined : json['referral_code'],
        'referredBy': json['referred_by'] == null ? undefined : UserFromJSON(json['referred_by']),
        'promotion': json['promotion'] == null ? undefined : PromotionFromJSON(json['promotion']),
    };
}

export function ReferralToJSON(value?: Referral | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'referral_code': value['referralCode'],
        'referred_by': UserToJSON(value['referredBy']),
        'promotion': PromotionToJSON(value['promotion']),
    };
}

